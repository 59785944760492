import * as React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import GoogleLogin from './GoogleLogin';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useUser } from '../contexts/UserContext';
import { useLogin } from '../contexts/LoginContext';
import LoginModal from './LoginModal';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!).catch((error) => {
  console.error('Failed to load Stripe:', error);
});


export interface Plan {
  title: string;
  price: string;
  description: string[];
  buttonText: string;
  buttonVariant: 'outlined' | 'contained';
  buttonColor: 'primary' | 'secondary';
  priceId?: string;
  subheader?: string;
}

export const tiers: Plan[] = [
  {
    title: 'Free',
    price: '0',
    description: [
      'Up to 4 clips per video',
      'Up to 2 videos per month',
      "We store your clips for 24 hours",
      'Uses our least sophisticated AI',
      'Viryl Watermark',
      'Viryl Outro',
    ],
    buttonText: 'Sign up with Google',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
  },
  {
    title: 'Basic',
    subheader: 'Recommended',
    price: '15',
    description: [
      'Up to 6 clips per video',
      'Up to 30 videos per month',
      'We store your clips for 7 days',
      'Sophisticated AI',
      'No Viryl Watermark',
      'No Viryl Outro',
    ],
    buttonText: 'Start now',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
    priceId: process.env.REACT_APP_VIRYL_PRO,
  },
  {
    title: 'Professional',
    price: '30',
    description: [
      'Up to 8 clips per video',
      'Up to 100 videos per month',
      'Unlimited storage of your clips',
      'Uses our most sophisticated AI',
      'No Viryl Watermark',
      'No Viryl Outro',
    ],
    buttonColor: 'secondary',
    buttonText: 'Start now',
    buttonVariant: 'outlined',
    priceId: process.env.REACT_APP_VIRYL_PREM,
  },
];

export default function Pricing() {
  const { user } = useUser();
  const { openLoginModal } = useLogin();

  const handleSubscribe = async (priceId: string | undefined) => {
    if (!user) {
      openLoginModal();
      return;
    }

    try {
      const stripe = await stripePromise;

      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }

      // Call your backend to create a Checkout session
      const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId: priceId }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const session = await response.json();

      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      // Handle the error gracefully, e.g., show an error message to the user
      alert('Sorry, there was an error processing your payment. Please try again later.');
    }
  };

  return (
    <>
      <Container
        id="pricing"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
            sx={{ color: 'text.primary' }}
          >
            Pricing
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Choose the perfect plan for your content creation needs. Start for free and
            upgrade as you see fit. <br />
            Our flexible pricing options are designed to support creators at every stage.
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          {tiers.map((tier) => (
            <Grid
              size={{ xs: 12, sm: tier.title === 'Professional' ? 12 : 6, md: 4 }}
              key={tier.title}
            >
              <Card
                sx={[
                  {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                  },
                  tier.title === 'Basic' &&
                  ((theme) => ({
                    border: 'none',
                    background:
                      'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    ...theme.applyStyles('dark', {
                      background:
                        'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                      boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                    }),
                  })),
                ]}
              >
                <CardContent>
                  <Box
                    sx={[
                      {
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      },
                      tier.title === 'Basic'
                        ? { color: 'grey.100' }
                        : { color: '' },
                    ]}
                  >
                    <Typography component="h3" variant="h6">
                      {tier.title}
                    </Typography>
                    {tier.title === 'Basic' && (
                      <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                    )}
                  </Box>
                  <Box
                    sx={[
                      {
                        display: 'flex',
                        alignItems: 'baseline',
                      },
                      tier.title === 'Basic'
                        ? { color: 'grey.50' }
                        : { color: null },
                    ]}
                  >
                    <Typography component="h3" variant="h2">
                      ${tier.price}
                    </Typography>
                    <Typography component="h3" variant="h6">
                      &nbsp; per month
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                    >
                      <CheckCircleRoundedIcon
                        sx={[
                          {
                            width: 20,
                          },
                          tier.title === 'Basic'
                            ? { color: 'primary.light' }
                            : { color: 'primary.main' },
                        ]}
                      />
                      <Typography
                        variant="subtitle2"
                        component={'span'}
                        sx={[
                          tier.title === 'Basic'
                            ? { color: 'grey.50' }
                            : { color: null },
                        ]}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  {tier.title === 'Free' ? (
                    <GoogleLogin />
                  ) : (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant as 'outlined' | 'contained'}
                      color={tier.buttonColor as 'primary' | 'secondary'}
                      onClick={() => handleSubscribe(tier.priceId)}
                      sx={{
                        backgroundColor: '#1AB69D',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#158F7D',
                        },
                      }}
                    >
                      {tier.buttonText}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <LoginModal />
      </Container>
    </>
  );
}
