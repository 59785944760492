import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,
    Grid,
    CircularProgress,
    Box,
} from '@mui/material';
import { CheckCircle, Bolt, InfoOutlined, Sledding, YouTube } from '@mui/icons-material';
import { tiers, Plan } from './Pricing';
import axios, { isAxiosError } from 'axios';
import AppBarToo from './AppBarToo';  // Add this import
import { loadStripe } from '@stripe/stripe-js';
import TikTokIcon from './TikTokIcon';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const SubscriptionManagement: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [currentPlan, setCurrentPlan] = useState<Plan | null>(null);
    const [availablePlans, setAvailablePlans] = useState<Plan[]>([]);
    const [youtubeAuthStatus, setYoutubeAuthStatus] = useState<'authenticated' | 'unauthenticated' | 'loading'>('loading');
    const [tikTokAuthStatus, setTikTokAuthStatus] = useState<'authenticated' | 'unauthenticated' | 'loading'>('loading');

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/get_current_plan`, { withCredentials: true });
                const currentPlanTitle = response.data.plan;
                setCurrentPlan(tiers.find(plan => plan.title.toLowerCase() === currentPlanTitle) || null);
                setAvailablePlans(tiers);
            } catch (error) {
                console.error('Error fetching subscription data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionData();
    }, []);

    useEffect(() => {
        const checkYouTubeAuthStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/youtube_auth_status`, { withCredentials: true });
                setYoutubeAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
            } catch (error) {
                console.error('Error checking YouTube auth status:', error);
                setYoutubeAuthStatus('unauthenticated');
            }
        };

        checkYouTubeAuthStatus();
    }, []);
    useEffect(() => {
        const checkTikTokAuthStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/tiktok_auth_status`, { withCredentials: true });
                setYoutubeAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
            } catch (error) {
                console.error('Error checking TikTok auth status:', error);
                setTikTokAuthStatus('unauthenticated');
            }
        };

        checkTikTokAuthStatus();
    }, []);

    const handleSubscribe = async (planId: string) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/create-portal-session`,
                { plan: planId },
                { withCredentials: true }
            );
            if (response.data.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            if (isAxiosError(error) && error.response?.status === 422) {
                // Create a new checkout session
                const stripe = await stripePromise;
                if (!stripe) {
                    throw new Error('Stripe failed to initialize');
                }

                const checkoutResponse = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/create-checkout-session`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ priceId: tiers.find(tier => tier.title === planId)?.priceId }),
                    credentials: 'include',
                });

                if (!checkoutResponse.ok) {
                    throw new Error(`HTTP error! status: ${checkoutResponse.status}`);
                }

                const session = await checkoutResponse.json();
                const result = await stripe.redirectToCheckout({
                    sessionId: session.id,
                });

                if (result.error) {
                    throw new Error(result.error.message);
                }
            } else {
                console.error('Error updating subscription:', error);
                alert('Sorry, there was an error processing your request. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/create-portal-session`, {}, { withCredentials: true });
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error canceling subscription:', error);
        } finally {
            setLoading(false);
        }
    };

    const getButtonText = (plan: Plan) => {
        if (loading) return "Processing...";
        if (currentPlan?.title === plan.title) return "Cancel Subscription";
        if (currentPlan?.title === 'Professional' && plan.title === 'Basic') return "Downgrade";
        if ((currentPlan?.title === 'Professional' || currentPlan?.title === 'Basic') && plan.title === 'Free') return "Downgrade";
        return "Upgrade";
    };

    const getButtonIcon = (plan: Plan) => {
        if ((currentPlan?.title === 'Professional' || currentPlan?.title === 'Basic') && plan.title === 'Free') return <Sledding />;
        if (plan.title === 'Professional') return <Bolt />;
        return null;
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!currentPlan) {
        return (
            <Box sx={{ maxWidth: 1200, margin: 'auto', p: 4, textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom>
                    Sorry, we couldn't find your subscription.
                </Typography>
                <Typography variant="body1">
                    Please try again later or contact us for assistance.
                </Typography>
            </Box>
        );
    }

    const handleYouTubeAuth = async () => {
        try {
            setYoutubeAuthStatus('loading');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/youtube_auth`, { withCredentials: true });
            if (response.data.auth_url) {
                window.location.href = response.data.auth_url;
            } else {
                setYoutubeAuthStatus('authenticated');
                alert('YouTube credentials have been set up or refreshed successfully.');
            }
        } catch (error) {
            console.error('Error with YouTube authentication:', error);
            alert('Sorry, there was an error with YouTube credentials. Please try again later.');
            setYoutubeAuthStatus('unauthenticated');
        }
    };

    const handleTikTokAuth = async () => {
        try {
            setTikTokAuthStatus('loading');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/tiktok_auth`, { withCredentials: true });
            if (response.data.auth_url) {
                window.location.href = response.data.auth_url;
            }
        } catch (error) {
            console.error('Error initiating TikTok authentication:', error);
            alert('Sorry, there was an error setting up TikTok credentials. Please try again later.');
            setTikTokAuthStatus('unauthenticated');
        }
    };

    return (
        <>
            <AppBarToo />
            <Box sx={{ maxWidth: "xl", margin: 'auto', p: 4 }}>
                <Typography variant="h5" gutterBottom fontWeight="bold">
                    Your Subscription
                </Typography>
                <Grid container spacing={3}>
                    {availablePlans.map((plan) => (
                        <Grid item xs={12} sm={6} md={4} key={plan.title}>
                            <Card
                                variant="outlined"
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: currentPlan?.title === plan.title ? '2px solid primary.main' : undefined
                                }}
                            >
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                        <Typography variant="h6">{plan.title}</Typography>
                                        {currentPlan?.title === plan.title && (
                                            <Box bgcolor="secondary.main" color="secondary.contrastText" px={1} py={0.5} borderRadius={1} display="flex" alignItems="center">
                                                <CheckCircle fontSize="small" sx={{ mr: 0.5 }} />
                                                <Typography variant="caption">Current Plan</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <Typography variant="h5" color="primary" gutterBottom>${plan.price}/month</Typography>
                                    <ul>
                                        {plan.description.map((feature, index) => (
                                            <li key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    {currentPlan?.title === plan.title ? (
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={handleCancel}
                                            disabled={loading || plan.title === 'Free'}
                                        >
                                            {loading ? "Processing..." : "Cancel Subscription"}
                                        </Button>
                                    ) : (
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            sx={{
                                                backgroundColor: '#1AB69D',
                                                '&:hover': {
                                                    backgroundColor: '#158F7D',
                                                },
                                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                width: '100%',
                                                color: 'white',
                                            }}
                                            onClick={() => handleSubscribe(plan.title)}
                                            disabled={loading}
                                            endIcon={getButtonIcon(plan)}
                                        >
                                            {getButtonText(plan)}
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box mt={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <InfoOutlined color="warning" sx={{ mr: 1 }} />
                                <Typography variant="h6">Important Information</Typography>
                            </Box>
                            <Typography>
                                If you upgrade your plan, the changes will take effect immediately.
                                If you downgrade your plan, the changes will take effect at your next billing date.
                                Your billing will be adjusted accordingly in both cases.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={4}>
                    <Typography sx={{ mx: 1 }} variant="h5" gutterBottom fontWeight="bold">
                        Your Credentials
                    </Typography>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <YouTube color="error" sx={{ mr: 1 }} />
                                <Typography variant="h6">YouTube Credentials</Typography>
                            </Box>
                            <Typography variant="body1" mb={2}>
                                {youtubeAuthStatus === 'authenticated'
                                    ? 'Your YouTube credentials are set up. You can refresh them if needed.'
                                    : 'Set up your YouTube credentials to enable video uploads directly from our platform.'}
                            </Typography>
                            <Box display="flex" alignItems="center" gap={2}>
                                {youtubeAuthStatus === 'authenticated' && (
                                    <Box display="flex" alignItems="center">
                                        <CheckCircle color="success" sx={{ mr: 1 }} />
                                        <Typography variant="body1">Credentials ready</Typography>
                                    </Box>
                                )}
                                <Button
                                    variant={youtubeAuthStatus === 'authenticated' ? "outlined" : "contained"}
                                    color="error"
                                    startIcon={<YouTube />}
                                    onClick={handleYouTubeAuth}
                                    disabled={youtubeAuthStatus === 'loading'}
                                >
                                    {youtubeAuthStatus === 'loading' ? 'Processing...' :
                                        youtubeAuthStatus === 'authenticated' ? 'Refresh Credentials' : 'Set Up YouTube Credentials'}
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                <Box mt={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <TikTokIcon sx={{ mr: 1, color: "black" }} />
                                <Typography variant="h6">TikTok Credentials</Typography>
                            </Box>
                            {tikTokAuthStatus !== 'authenticated' && <Typography variant="body1" mb={2}>
                                Set up your TikTok credentials to enable video uploads directly from our platform.
                            </Typography>}
                            {tikTokAuthStatus === 'authenticated' ? (
                                <Box display="flex" alignItems="center">
                                    <CheckCircle color="success" sx={{ mr: 1 }} />
                                    <Typography variant="body1">TikTok credentials are set up and ready to use.</Typography>
                                </Box>
                            ) : (
                                <Button
                                    variant="contained"
                                    startIcon={<TikTokIcon />}
                                    onClick={handleTikTokAuth}
                                    disabled={tikTokAuthStatus === 'loading'}
                                >
                                    {tikTokAuthStatus === 'loading' ? 'Setting up...' : 'Set Up TikTok Credentials'}
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                </Box>

            </Box>
        </>
    );
};

export default SubscriptionManagement;