import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'; // Update axios import
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

interface GoogleLoginProps {
    onSuccess?: () => void;
}

const GoogleLogin: React.FC<GoogleLoginProps> = ({ onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const { setUser } = useUser();
    const navigate = useNavigate();

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/login`, {
                    token: tokenResponse.access_token
                }, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log('Login successful:', response.data);
                setUser(response.data);
                onSuccess?.(); // Call the onSuccess callback if provided
                navigate("/viral-action")
            } catch (error) {
                console.error('Login failed:', error);
                // Handle login error
            } finally {
                setLoading(false);
            }
        },
        onError: () => {
            console.log('Login Failed');
            setLoading(false);
        },
    });

    return (
        <Button
            variant="outlined"
            onClick={() => login()}
            disabled={loading}
            sx={{
                backgroundColor: '#1AB69D',
                '&:hover': {
                    backgroundColor: '#158F7D', // Slightly darker shade for hover effect
                },
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                width: '100%',
                color: 'white',
            }}

        >
            {loading ? <CircularProgress size={24} /> : 'Sign in via Google'}
        </Button>
    );
};

export default GoogleLogin;